import { writable } from 'svelte/store';
import { browser } from '$app/env';

/* 
globalAlert = { 
    type = string
    message = string
    timeout = number (ms)
}
*/

const storedGlobalAlert = JSON.parse(browser && localStorage.getItem('globalAlert')) || {};
export const globalAlert = writable(browser && storedGlobalAlert);
globalAlert.subscribe((val) => browser && (localStorage.globalAlert = JSON.stringify(val)));
